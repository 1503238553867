/* New Simulation Container */
.NewSimulationContainer {
    background-color: white;
    border-radius: 15px;
    padding: 20px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
  }
  
  .NewSimulationTitle {
    font-size: 24px;
    font-weight: bold;
    color: #333;
    margin-bottom: 20px;
  }
  
  .NewSimulationForm {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
  
  .NewSimulationInput {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
  }
  
  .UserInputContainer {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 20px; /* Add space between name input and user inputs */
  }
  
  .UserInput {
    background-color: #f5f5f5; /* Light gray background for user inputs */
  }
  
  .CreateSimulationButton {
    background-color: #D03426;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    font-size: 16px;
    margin-top: 20px;
  }
  
  .CreateSimulationButton:hover {
    background-color: #a8281f;
  }
  
  /* Error Message */
  .ErrorMessage {
    color: red;
    font-size: 14px;
    margin-top: 10px;
  }