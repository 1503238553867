/* Floating Simulation Cards */
.SimulationCard {
    background-color: white;
    border-radius: 15px;
    padding: 20px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
  }
  
  /* Simulation Header within each card */
  .SimulationHeaderCard {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  /* Simulation Name */
  .SimulationName {
    font-size: 24px;
    font-weight: bold;
    color: #333;
  }
  
  /* Load Simulation Button */
  .ViewSummaryButton {
    background-color: #D03426; /* DEH red */
    color: white;
    padding: 8px 16px;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    font-size: 14px;
  }
  
  .ViewSummaryButton:hover {
    background-color: #a8281f;
  }
  
  /* List of People within each simulation card */
  .PeopleList {
    display: flex;
    flex-direction: column;
    gap: 10px; /* Space between each person */
    margin-top: 10px;
  }
  
  /* Individual person card (name and chat count) */
  .PersonCard {
    background-color: #f0f0f0; /* Light gray background for person */
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 15px;
    border-radius: 10px;
    cursor: pointer;
    border: 0px solid #ddd;
  }
  
  .PersonCard:hover {
    background-color: #e5e5e5; /* Slightly darker on hover */
  }
  
  /* Person's name inside the person card */
  .PersonName {
    font-size: 16px;
    color: #333;
  }
  
  /* Chat count inside the person card */
  .ChatCount {
    font-size: 14px;
    color: #666;
  }