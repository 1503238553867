/* global.css */
body, html {
  margin: 0;
  font-family: Arial, sans-serif;
  background-color: #f5f5f5;
  height: 100%;
  overflow: hidden;
}

.App {
  display: flex;
  flex-direction: column;
  height: 100vh;
  position: relative;
  overflow: hidden; /* Ensure no overflow beyond the screen */
}

.App-logo {
  position: absolute;
  top: 15px;
  left: 15px;
  height: auto;
  width: 10%;
  object-fit: contain;
}

.home-button {
  position: absolute;
  top: 20px;
  right: 20px;
  width: 50px;
  height: 50px;
  background-color: white;
  border-radius: 10px; /* Rounded corners */
  border: none; /* No border */
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); /* Optional shadow */
  cursor: pointer;
  padding: 10px; /* Optional padding to keep the icon centered */
}

.home-button:hover {
  background-color: #f0f0f0; /* Slight hover effect */
}

.home-icon {
  width: 34px;
  height: 34px;
  fill: lightgray; /* This will apply to inline SVG, making it gray */
}