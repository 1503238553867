.MessageList {
  flex: 1;
  overflow-y: auto;
  padding: 60px 30px 110px 30px; /* Padding at the bottom to ensure messages don't go under the chat bar */
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 15px;
  scrollbar-width: none;
  -ms-overflow-style: none;
  overscroll-behavior: contain;
  margin: 0 10%; /* Margin to center the content */
  height: calc(100vh - 50px); /* Adjust height to ensure proper scrolling */
}

.MessageList::-webkit-scrollbar { 
  display: none;
}

.Message {
  padding: 15px 15px;
  border-radius: 20px;
  max-width: 80%; /* Limit message width to 80% */
  word-wrap: break-word;
}

.Message.incoming {
  background-color: #f0f2f5;
  align-self: flex-start;
  color: black;
}

.Message.outgoing {
  align-self: flex-end;
  color: white;
}

.code-language {
  position: absolute;
  top: 10px;
  left: 10px;
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  font-size: 12px;
  padding: 2px 6px;
  border-radius: 3px;
  text-transform: uppercase;
}

.code-block pre {
  margin: 20px 0 0 0;
  white-space: pre-wrap;
}

.copy-button:hover {
  background-color: #0056b3;
}

.bullet-dot {
  flex-shrink: 0; /* Prevent the dot from being resized */
  width: 6px;
  height: 6px;
  background-color: rgb(74, 131, 241); /* Wazuh blue */
  border-radius: 50%;
  margin-right: 10px;
  display: inline-block;
  margin-top: 5px; /* Adjust this value to better align the dot with the text */
}

.code-block {
  position: relative;
  margin: 10px 0;
  padding: 10px;
  background-color: #f5f5f5;
  border: 1px solid #ddd;
  border-radius: 5px;
}

.copy-button {
  position: absolute;
  top: 5px;
  right: 5px;
  background-color: rgb(74, 131, 241);
}

.bullet-point {
  display: flex;
  align-items: flex-start;
}

.bullet-text {
  display: inline-block;
  margin-left: 5px;
}

.code-block {
  position: relative;
  margin: 10px 0;
  padding: 10px;
  background-color: #f5f5f5;
  border: 1px solid #ddd;
  border-radius: 5px;
}

.copy-button {
  position: absolute;
  top: 5px;
  right: 5px;
  border-radius: 8px;
  border: 0px;
  padding: 5px;
  color: #f5f5f5;
}

.link-image-button {
  position: relative;
  display: inline-block;
  vertical-align: top;
  margin-left: 5px; /* Adjust the space between the text and icon */
}

.link-image-button img {
  width: 14px;  /* Smaller size for the icon */
  height: 14px;
  object-fit: contain;
  cursor: pointer;
  display: inline-block;
  position: absolute;
  top: -3px;  /* Adjust this value to align it slightly higher */
  right: -4px; /* Align it more to the right */
}

.BlueDot {
  display: inline-block;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin-left: 5px;
  vertical-align: middle;
}

.pulsing {
  animation: pulse 1s infinite;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.5);
  }
  100% {
    transform: scale(1);
  }
}

.deh-demo .BlueDot {
  background-color: rgb(227, 25, 19);
}

.wazuh-demo .BlueDot {
  background-color: rgb(74, 131, 241);
}

.deh-demo .Message.outgoing {
  background-color: rgb(227, 25, 19);
}

.wazuh-demo .Message.outgoing {
  background-color: rgb(74, 131, 241);
}