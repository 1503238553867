.StatusIndicator {
    position: fixed;
    bottom: 110px; /* Adjusted to ensure it's above the chat bar */
    left: 50%;
    transform: translateX(-50%);
    font-size: 14px;
    padding: 10px 20px;
    border-radius: 15px;
    text-align: center;
    font-weight: bold;
    background-color: transparent;
    border: 2px solid;
    z-index: 10;
  }
  
  .StatusIndicator.Thinking {
    color: lightgray;
    border-color: lightgray;
    animation: fadeInOut 1.5s infinite;
  }
  
  .StatusIndicator.Documentation {
    color: rgb(74, 131, 241); /* Wazuh blue */
    border-color: rgb(74, 131, 241);
    animation: fadeInOut 1.5s infinite;
  }

  .StatusIndicator.Support {
    color: rgb(255, 86, 0); /* Wazuh blue */
    border-color: rgb(255, 86, 0);
    animation: fadeInOut 1.5s infinite;
  }

  .StatusIndicator.RuleDecoder {
    color: rgb(255, 221, 0); /* Wazuh blue */
    border-color: rgb(255, 221, 0);
    animation: fadeInOut 1.5s infinite;
  }
  
  @keyframes fadeInOut {
    0% { opacity: 1; }
    50% { opacity: 0.5; }
    100% { opacity: 1; }
  }
  
  .StatusIndicator.Done {
    color: green;
    border-color: green;
  }