.MessageInputContainer {
  display: flex;
  align-items: center;
  padding: 10px;
  box-sizing: border-box;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  border-radius: 19px;
  background-color: white;
  flex-shrink: 0;
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  width: calc(80% - 0px);
  max-width: 1400px;
  z-index: 10;
}

.MessageInput {
  flex: 1;
  padding: 13px;
  border: 2px solid rgb(195, 204, 216);
  border-radius: 10px;
  outline: none;
  box-sizing: border-box;
  font-size: 18px;
}

.SendButton {
  margin-left: 10px;
  padding: 15px 40px;
  border: none;
  border-radius: 10px;
  color: white;
  font-size: 18px;
  cursor: pointer;
  outline: none;
  box-sizing: border-box;
  transition: background-color 0.3s ease;
}

.SendButton.disabled {
  background-color: rgb(100, 126, 176);
  cursor: not-allowed;
  animation: fadeInOut 1.5s infinite;
}

.SendButton:hover:not(.disabled) {
  background-color: rgb(50, 100, 200);
}

.ChatBarBackground {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 70px;
  background-color: #f5f5f5; /* Same as background color */
  z-index: 5; /* Ensure it sits behind the chat bar */
}

.DropdownMenu {
  margin-right: 10px;
  padding: 13px;
  border: 2px solid rgb(195, 204, 216);
  border-radius: 10px;
  outline: none;
  box-sizing: border-box;
  font-size: 18px;
  color: rgb(117, 117, 117);
}

.deh-demo .SendButton {
  background-color: rgb(227, 25, 19);
}

.wazuh-demo .SendButton {
  background-color: rgb(74, 131, 241);
}

