/* DehDemoOverview Container */
.DehDemoOverview {
  display: flex;
  background-color: #f5f5f5; /* Light gray background */
  min-height: 100vh; /* Ensure full viewport height */
  padding: 20px;
  box-sizing: border-box;
  overflow: hidden; /* Prevent overall container from scrolling */
}

/* Navigation Bar - Keep it fixed */
.NavigationBar {
  background-color: white;
  border-radius: 15px;
  padding: 20px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-right: 20px;
  flex-shrink: 0; /* Prevent shrinking */
  position: sticky; /* Keep it fixed while scrolling */
  top: 20px; /* Stick to the top of the viewport */
  height: fit-content; /* Adjust the height to fit the content */
}

/* DEH Logo */
.DehLogo {
  height: 50px;
  margin-bottom: 30px;
}

/* Simulation Info */
.SimulationInfo {
  width: 100%;
  text-align: left;
  margin-bottom: 30px;
}

.SimulationInfo h2 {
  font-size: 22px; /* Increased font size */
  margin: 0;
}

.SimulationInfo p {
  font-size: 18px; /* Increased font size */
  margin: 5px 0;
}

/* Navigation Buttons */
.NavButtons {
  display: flex;
  flex-direction: column;
  width: 100%; /* Make buttons take full width */
  gap: 10px;
}

.NavButton {
  background-color: #f5f5f5; /* Light gray background */
  color: #333;
  padding: 15px;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  font-size: 18px; /* Larger text */
  width: 100%;
  text-align: center;
}

.NavButton.selected {
  background-color: #D03426; /* DEH red */
  color: white;
}

.NavButton:hover {
  background-color: #e0e0e0; /* Slightly darker on hover */
}

/* Content Area - Scrollable */
.ContentArea {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 20px; /* Space between floating rectangles */
  overflow-y: auto; /* Enable vertical scrolling */
  max-height: 100vh; /* Ensure it doesn't exceed the viewport height */
  padding: 20px;
  padding-top: 0px;
  background-color: transparent;

  /* Smooth scrolling */
  scroll-behavior: smooth;

  /* Bounce effect with overscroll */
  overscroll-behavior: contain;

  /* Hide scrollbar */
  scrollbar-width: none; /* Firefox */
}

/* Hide scrollbar for Webkit browsers (Chrome, Safari) */
.ContentArea::-webkit-scrollbar {
  display: none;
}

/* Scroll Snap Effect */
.ContentArea > * {
  scroll-snap-align: start; /* Snap each content block at the start */
}

/* Add a subtle shadow to indicate scrolling */
.ContentArea::before, .ContentArea::after {
  content: '';
  display: block;
  height: 10px; /* Spacing to create a bounce effect */
  background-color: transparent;
}